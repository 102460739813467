import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Button } from "../components/button";
import Layout from "../components/layout";
import Section from "../components/section";
import CTADemo from "../components/sections/cta-demo";
import SEO from "../components/seo";

const PTE = () => {
    return(
        <Layout>
            <section id="hero" className="relative bg-slate-900/70 after:bg-slate-900/60 after:block after:h-full after:absolute after:right-0 after:left-0 after:top-0 after:content-['']">
                <div className="z-0 absolute left-0 bottom-0 right-0 top-0 overflow-hidden">
                    <StaticImage alt="CCW2023" src="../assets/PTE-Landing-Hero.jpeg" className="w-full h-full" imgClassName="object-cover object-bottom w-full h-full" layout="fullWidth" />
                </div>

                <div className="z-10 relative flex h-full items-center justify-center text-center px-8 py-36 sm:py-48 md:py-60 xl:px-0 container max-w-7xl mx-auto">
                    <div className="text-white">
                        <div className="space-y-2">
                            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold">
                                Meet DFS at Passenger Terminal EXPO 2023 and Learn about Coeus
                            </h1>
                        </div>
                    </div>
                </div>
            </section>

            <CTADemo />

            <Section id="case-studies" className="bg-gray-50">
                <h1 className="text-center font-bold text-5xl">Case Studies</h1>
                <div className="mt-10 space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-4 content-center">
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/PTE-Landing-1.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #1"
                        />
                        <h2 className="text-xl font-bold py-2">Meeting Customer Demands in the Airport Industry</h2>
                        <a href="#case-study-1">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/PTE-Landing-2.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #2"
                        />
                        <h2 className="text-xl font-bold py-2">Streamlining Customer Service in Airports</h2>
                        <a href="#case-study-2">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/PTE-Landing-3.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #3"
                        />
                        <h2 className="text-xl font-bold py-2">Revolutionizing Call Centers and Customer Service</h2>
                        <a href="#case-study-3">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/PTE-Landing-4.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #4"
                        />
                        <h2 className="text-xl font-bold py-2">Innovative Solution for Customer Service Experience</h2>
                        <a href="#case-study-4">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/PTE-Landing-5.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #5"
                        />
                        <h2 className="text-xl font-bold py-2">Transforming Customer Service with Emerging Technologies</h2>
                        <a href="#case-study-5">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/PTE-Landing-6.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #6"
                        />
                        <h2 className="text-xl font-bold py-2">Learn more about Coeus: Your AI-Enhanced Virtual Assistant</h2>
                        <a href="#case-study-6">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                </div>
            </Section>

            <article
                itemScope
                itemType="http://schema.org/Article"
                className="px-10 lg:px-0 py-10 space-y-6 font-openSans prose-base lg:prose-lg font-normal"
            >
                {/* Case Study #1 Article */}
                <Section id="case-study-1">
                    <h2 className="font-medium">Meeting Customer Demands in the Airport Industry</h2>
                    <div className="py-4 grid lg:grid-cols-3">
                        <StaticImage
                            src="../assets/PTE-Landing-1.jpeg"
                            className="shadow-md rounded-xl"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            alt="Case Study #1"
                        />
                        <div className="lg:pl-8 lg:col-span-2">
                            <p>You’re late for a flight and need to know the fastest TSA checkpoint. You’re at the 
                            gate and realize you don’t have a phone charger packed. Where is the closest place
                            to buy one? Your flight has just been delayed and you’re hungry. What are the food 
                            options near your gate? You don’t want to stand in line to ask questions or 
                            directions. This is where Coeus can help, by providing you with the information you 
                            need right on your mobile device.</p>
                            <p>In today's world, businesses, including airports, need to keep up with the increasing
                            demands of their customers who want to get things done quickly and efficiently. To 
                            achieve customer satisfaction in 2023, it's essential for organizations to offer quick 
                            solutions, answer questions promptly, and provide 24x7 access to information 
                            without requiring customers to wait for an agent.</p>
                        </div>
                    </div>
                    <p>To keep up with these demands, businesses must be more flexible in terms of 
                        technology and human resources. This means supporting customers across 
                        different channels by  being able to effectively serve their needs across self-service,
                        automated, and person-to-person interactions. Coeus is an AI-
                        driven tool that combines an easy-to-use interface with customization to provide 
                        the fast and efficient experience that customers expect.</p>

                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>

                {/* Case Study #2 Article */}
                <Section id="case-study-2" className="bg-gray-50">
                    <h2 className="font-medium">Streamlining Customer Service in Airports</h2>
                    <div className="py-4 grid lg:grid-cols-3">
                        <div className="lg:pr-8 lg:col-span-2">
                            <p>Coeus is an AI-powered assistant that can provide intelligent responses to 
                            passengers, visitors, and customer service representatives for your business. It is 
                            designed to automate routine and complex inquiries, which can be accessed 
                            directly by your customers. Coeus can be integrated with your business systems 
                            and knowledge bases to provide natural language responses that are in-depth and 
                            consistent with your users' preferences and devices. Additionally, Coeus can learn 
                            and improve over time, which leads to a richer customer experience, reduced 
                            operational costs, and safe, virtual interactions for your airport's customer-focused 
                            aspects.</p>
                        </div>
                        <StaticImage
                            src="../assets/PTE-Landing-2.jpeg"
                            className="shadow-md rounded-xl"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            alt="Case Study #2"
                        />
                    </div>
                    <p>Coeus has several benefits, including enabling agents to serve customers faster, 
                    providing up-to-date answers during self-service interactions, and speeding up the 
                    onboarding process for new agents. Many customers report that they cannot 
                    resolve issues on their own due to a lack of information online. With the help of AI, 
                    Coeus can quickly access your knowledge content and provide
                    the information to your customers, in their preferred language, in a way that is easy
                    to understand.</p>
                    
                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>

                {/* Case Study #3 Article */}
                <Section id="case-study-3">
                    <h2 className="font-medium">Revolutionizing Call Centers and Customer Service</h2>
                    <div className="py-4 grid lg:grid-cols-3">
                        <StaticImage
                            src="../assets/PTE-Landing-3.jpeg"
                            className="shadow-md rounded-xl"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            alt="Case Study #3"
                        />
                        <div className="lg:pl-8 lg:col-span-2">
                            <p>Coeus is a versatile tool that can assist and bring consistency to traditional 
                            customer call centers, regardless of the industry. Call centers are currently 
                            overwhelmed with a high volume of calls, and high agent turnover is resulting in 
                            significant, often unnecessary expenses for your business. By using Coeus as an 
                            agen- assist tool, your agents can provide faster information lookup for your 
                            customers while maintaining consistent responses across all agents. With Coeus, 
                            agents no longer need to search through multiple applications and knowledge 
                            sources to prepare a response. Coeus can comb through information from all 
                            sources and provide a seamless response in one go</p>
                        </div>
                    </div>
                    <p>Coeus's user-friendly interface and multi-system access through a single inquiry can
                    make training new agents quicker, leading to less training time and faster agent 
                    productivity. Coeus can interact with your call center and 
                    customer service agents to reduce training time for new agents, decrease call 
                    times, orchestrate the use of existing systems, and provide a solution in as little as 
                    six weeks.</p>

                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>

                {/* Case Study #4 Article */}
                <Section id="case-study-4" className="bg-gray-50">
                    <h2 className="font-medium">Innovative Solution for Customer Service Experience</h2>
                    <div className="py-4 grid lg:grid-cols-3">
                        <div className="lg:pr-8 lg:col-span-2">
                            <p>As the preference for virtual chat support continues to grow, companies are facing 
                            challenges in creating efficient and user-friendly virtual assistants. Young people 
                            entering the workforce or heading off to college are comfortable with technology 
                            and impatient with waiting on a live agent to answer their questions. On the other 
                            hand, older generations often struggle with automated phone services that fail to 
                            understand their spoken responses, leading to frustration and repetition. Moreover, 
                            call center agents often have to sift through multiple sources to provide answers, 
                            resulting in inconsistent and sometimes incorrect information being communicated 
                            to customers.</p>
                        </div>
                        <StaticImage
                            src="../assets/PTE-Landing-4.jpeg"
                            className="shadow-md rounded-xl"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            alt="Case Study #4"
                        />
                    </div>
                    <p><a href="https://dfs.team/" className="underline">Data Fusion Specialists</a> has developed a platform on top of IBM Watson Assistant and Watson Discovery to address these challenges. To ensure 
                    optimal results for each company, DFS conducts a Design Thinking Workshop at the
                    beginning of each implementation. This workshop involves direct stakeholders, as 
                    well as potential customers, to identify current pain points and future expectations 
                    based on their needs. DFS believes in putting the voice of the customer first to 
                    provide the best possible results for each company's success in the customer 
                    service experience.</p>
                    
                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>

                {/* Case Study #5 Article */}
                <Section id="case-study-5">
                    <h2 className="font-medium">Transforming Customer Service with Emerging Technologies</h2>
                    <div className="py-4 grid lg:grid-cols-3">
                        <StaticImage
                            src="../assets/PTE-Landing-5.jpeg"
                            className="shadow-md rounded-xl"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            alt="Case Study #5"
                        />
                        <div className="lg:pl-8 lg:col-span-2">
                            <p>Technological advancements are transforming the customer service landscape, with innovations like social media, self-service platforms, and customer journey mapping
                            revolutionizing the way businesses provide customer experiences. While technology
                            can play a significant role in providing customers with agent-less assistance, having
                            friendly staff available to provide human touch is still essential.</p>
                            <p>However, finding the right balance of agent staffing to reduce attrition remains a 
                            challenge for businesses. To address this issue, Coeus offers 
                            agent-assist technology, empowering agents with access to all knowledge bases 
                            and data sources via a single, conversational user interface. This technology 
                            ensures consistency in customer responses, eliminates the need for extensive 
                            agent training, and enables agents to handle customer interactions more effectively
                            and efficiently, resulting in greater job satisfaction.</p>
                        </div>
                    </div>
                    

                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>

                {/* Case Study #6 Article */}
                <Section id="case-study-6" className="bg-gray-50">
                    <h2 className="font-medium">Learn more about Coeus: Your AI-Enhanced Virtual Assistant</h2>
                    <div className="py-4 grid lg:grid-cols-3">
                        <div className="lg:pr-8 lg:col-span-2">
                            <p>Coeus is an AI-enhanced virtual assistant that can manage a variety of inquiries for 
                            your company, including both simple and complicated questions. For more complex
                            queries that require a deeper level of understanding, Coeus can direct customers to
                            the appropriate personnel within your organization. By integrating with your 
                            company's systems and databases, Coeus can provide detailed and consistent 
                            answers using natural language, adapting to user preferences and device types. 
                            This allows for a seamless and personalized customer experience while also 
                            reducing costs and expanding the opportunities for safe and virtual interaction with 
                            your business.</p>
                        </div>
                        <StaticImage
                            src="../assets/PTE-Landing-6.jpeg"
                            className="shadow-md rounded-xl"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            alt="Case Study #6"
                        />
                    </div>
                    
                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>

            </article>
        </Layout>
    )
};

export default PTE;

export const Head = () => {
    return (
        <SEO
            title="Passenger Terminal EXPO"
            description="Meet DFS at Passenger Terminal EXPO 2022 and Learn about Coeus"
        >
        </SEO>
    );
};
